import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostHeader from '../components/PostHeader';
import Footer from '../components/Footer';
import { formatReadingTime } from '../utils/helpers';
import { replaceAnchorLinksByLanguage, loadFontsForCode } from '../utils/i18n';
import './blog-post.css';

const systemFont = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif`;

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark;
        const siteTitle = get(this.props, 'data.site.siteMetadata.title');
        let { previous, next } = this.props.pageContext;
        const lang = post.fields.langKey;

        // Replace original links with translated when available.
        const html = post.html;

        loadFontsForCode(lang);

        return (
            <Layout location={this.props.location} title={siteTitle} locale={lang}>
                <SEO
                    lang={lang}
                    title={post.frontmatter.title}
                    description={post.frontmatter.description}
                    slug={post.fields.slug}
                />
                <main style={{ minHeight: '60vh' }}>
                    <article>
                        <PostHeader
                            title={post.frontmatter.title}
                            date={post.frontmatter.date}
                            timeToRead={formatReadingTime(post.timeToRead)}
                            lang={lang}
                        />
                        <div class="prose">
                            {post.frontmatter.cover && post.frontmatter.cover.name !== 'placeholder' ? (
                                <figure>
                                    <Img fluid={post.frontmatter.cover.childImageSharp.fluid} />
                                    {post.frontmatter.coverSource ? (
                                        <figcaption className="caption">
                                            {post.frontmatter.coverSource}
                                        </figcaption>
                                    ) : null}
                                </figure>
                            ) : null}
                            <div dangerouslySetInnerHTML={{ __html: html }} />
                        </div>
                    </article>
                </main>
                <aside>
                    <nav>
                        <ul
                            style={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'space-between',
                                listStyle: 'none',
                                padding: 0,
                            }}
                        >
                            <li>
                                {previous && (
                                    <Link
                                        to={`/${previous.frontmatter.url}`}
                                        rel="prev"
                                        style={{ marginRight: 20 }}
                                    >
                                        ← {previous.frontmatter.title}
                                    </Link>
                                )}
                            </li>
                            <li>
                                {next && (
                                    <Link to={`/${next.frontmatter.url}`} rel="next">
                                        {next.frontmatter.title} →
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </nav>
                </aside>
                <Footer locale={lang} />
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($url: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(frontmatter: { url: { eq: $url } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        spoiler
        description
        cover {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        url
        coverSource
      }
      fields {
        slug
        langKey
      }
    }
  }
`;
